<template lang="html">

  <section class="profile">
    <b-card no-body class="overflow-hidden" style="width: 100%;">
      <b-card-body title="プロフィール">
        <b-row no-gutters>
          <b-col md="9">
            <b-card-text>
              弁護士 三枝恵真（さいぐさ えま）
            </b-card-text>
            <b-card-text>
              所属 東京弁護士会（５５期）<br/>
              出身地 埼玉県
            </b-card-text>
            <b-card-text>
              <h3>経歴</h3>
            </b-card-text>
            <b-card-text>
              1990年3月 国立お茶の水女子大学付属高等学校卒業
              <br/>
              1994年3月 慶應義塾大学法学部法律学科卒
              <br/>
              民間企業に就職
              <br/>
              2000年11月 司法試験合格
              <br/>
              2002年10月 弁護士登録
              <br/>
              埼玉県内、都内法律事務所に所属
              <br/>
              2020年8月 三枝法律事務所設立
            </b-card-text>
            <b-card-text>
              <h3>活動歴</h3>
            </b-card-text>
            <b-card-text>
              東京弁護士会 監事 (2021年)
              <br/>
              東京弁護士会 常議員（2017年）
              <br/>
              東京三弁護士会医療関係事件検討協議会 委員長（2018年）
              <br/>
              東京三弁護士会 医療ADR仲裁人
              <br/>
              医療問題弁護団 副幹事長
              <br/>
              日本女性法律家協会 副会長（2019年～2020年）
              <br/>
              人権擁護委員（法務省）（2006年～2016年）
            </b-card-text>
            <b-card-text>
              <h3>著書等（すべて共著）</h3>
            </b-card-text>
            <b-card-text>
              「医療事故の法律相談」（鈴木利廣監修 2009年10月 学陽書房）
              <br/>
              「患者のための医療法律相談」（石川順子、谷直樹編 2010年9月 法学書院）
              <br/>
              「離婚給付算定事例集」（宇田川濱江ほか編 2010年２月 新日本法規出版）
              <br/>
              「家事調停の実務」（紙子達子ほか編 2014年9月 青林書院）
              <br/>
              「実務家が陥りやすい 離婚の落とし穴」（新日本法規出版 出版予定）
            </b-card-text>
          </b-col>
          <b-col md="3">
            <b-card-img src="/prof_saigsa.JPG" alt="Image"
                        class="rounded-0"></b-card-img>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>

</template>

<script lang="js">

  export default {
    name: 'Profile',
    props: [],
    mounted() {

    },
    data() {
      return {}
    },
    methods: {},
    computed: {}
  }


</script>

<style scoped lang="scss">
  .profile {

  }
</style>
