import Vue from 'vue'
import App from './App.vue'
import vuetify from "./plugins/vuetify";
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import router from "./plugins/router";
import VueRouter from "vue-router";
import './custom.scss'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueRouter)

new Vue({
  render: h => h(App),
  vuetify,
  router
}).$mount('#app')
