<template>
    <div id="app">
        <div class="container">
            <div class="top-title">
                <h4>三枝法律事務所</h4>
            </div>
            <div class="navbar">
                <b-nav justified tabs>
                    <!-- <b-nav-item>'s with child routes. Note the trailing slash on the first <b-nav-item> -->
                    <b-nav-item to="/" exact exact-active-class="active">トップページ</b-nav-item>
                    <b-nav-item to="/profile" exact exact-active-class="active">プロフィール</b-nav-item>
                    <b-nav-item to="/works" exact exact-active-class="active">取扱い業務</b-nav-item>
                    <b-nav-item to="/access" exact exact-active-class="active">アクセス</b-nav-item>
                    <b-nav-item to="/contact" exact exact-active-class="active">お問い合わせ</b-nav-item>
                </b-nav>
            </div>
            <div class="body-zone">
                <router-view></router-view>
            </div>
            <mdb-footer class="v-footer">
                <div>三枝法律事務所 all rights reserved.</div>
            </mdb-footer>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'App',
        components: {}
    }
</script>

<style>
    #app {
        font-family: 'SugikojoMIncho', '游明朝', 'Yu Mincho', YuMincho, serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        background-color: #aec1e33d;
        min-height: 100vh;
    }

    .top-title {
        height: 3rem;
        background-color: #aec1e3;
        display: flex;
        padding: 0 1rem;
        color: white;
        align-items: center;
    }

    .container {
        min-height: 100vh;
    }
    .body-zone {
        min-height: 82vh;
        height: 100%;
    }

    .v-footer {
        background-color: #aec1e3;
        color: white;
    }


    ul.nav.nav-tabs.nav-justified {
        width: 100%;
    }
</style>
