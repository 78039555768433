import Contact from "../pages/Contact";
import Access from "../pages/Access";
import Profile from "../pages/Profile";
import Works from "../pages/Works";
import Top from "../pages/Top";
import VueRouter from "vue-router";

const routes = [
    { path: '/', component: Top },
    { path: '/works', component: Works },
    { path: '/profile', component: Profile },
    { path: '/access', component: Access },
    { path: '/contact', component: Contact },
]

// 3. ルーターインスタンスを作成して、ルートオプションを渡します
// 追加のオプションをここで指定できますが、
// この例ではシンプルにしましょう
export default new VueRouter({
    routes // `routes: routes` の短縮表記
})
