<template lang="html">

    <section class="works">
            <b-card no-body class="overflow-hidden" style="width: 100%;">
                        <b-card-body title="取扱い業務">
                <b-row no-gutters>
                    <b-col md="8">
                            <b-card-text>主な取扱分野</b-card-text>
                            <b-card-text>医療過誤事件（患者側）</b-card-text>
                            <b-card-text>交通事故をはじめとする損害賠償請求事件</b-card-text>
                            <b-card-text>自己破産・民亊再生・任意整理など倒産、債務整理事件</b-card-text>
                            <b-card-text>離婚・養育費・面会交流・財産分与・慰謝料請求・年金分割など夫婦、家族の事件</b-card-text>
                            <b-card-text>遺産分割・遺言書作成・遺言執行・遺留分減殺請求など相続の事件</b-card-text>
                            <b-card-text>任意後見契約・成年後見の申立</b-card-text>
                    </b-col>
                    <b-col md="4">
                        <b-card-img src="/room.jpg" alt="Image"
                                    class="rounded-0"></b-card-img>
                    </b-col>
                </b-row>
                        </b-card-body>
            </b-card>
        </section>

</template>

<script lang="js">

    export default {
        name: 'Works',
        props: [],
        mounted() {

        },
        data() {
            return {}
        },
        methods: {},
        computed: {}
    }


</script>

<style scoped lang="scss">
    .works {

    }
</style>
