<template lang="html">

    <section class="Top">
        <div class="row">
            <div class="col">
                <div class="jumbotron">
                    <div class="text-right">ひとりひとりが尊重される社会へ</div>
                </div>
            </div>
        </div>
        <b-card no-body class="overflow-hidden" style="width: 100%;">
            <b-card-body title="ごあいさつ">
                <b-row no-gutters>
                    <b-col md="8">
                        <b-card-text>
                            当事務所は、弁護士１名の小さな法律事務所です。
                        </b-card-text>
                        <b-card-text>
                            私は、司法試験の勉強をしているときに、薬害HIV訴訟の原告（患者）の話を聞く機会がありました。その方の話の中で、自分ではどうにもならない被害、（当時）治療も難しい病、社会の差別偏見に触れ、このような被害の救済のために働きたいと思い、弁護士登録後は薬害や医療の被害救済に携わっています。
                        </b-card-text>
                        <b-card-text>
                            弁護士として活動をする中でも、先輩や同僚、事件の依頼者の方々から、人や社会のために働く意義や喜びを教わってきたと思います。
                        </b-card-text>
                        <b-card-text>
                            壁にぶつかりながらも何とか１つ１つを乗り越え、気が付けば20年近くが経ちました。実務家人生の１つの節目と考え、このたび、独立して法律事務所を開設しました。
                        </b-card-text>
                        <b-card-text>
                            トップページの雀の親子の写真は、大学の同級生でもある片柳弘史神父にお願いし、彼が撮った写真を使わせていただきました。彼の写真を見ていると、いつも目にする雀の姿も、こんなに生き生きと愛らしく、親子の愛情などにあふれていることに気づかされます。ご相談者が足元の日常の幸せを思い出せるような、事件処理をしていきたいと考えています。
                        </b-card-text>
                        <b-card-text>
                            2020年8月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;弁護士&nbsp;&nbsp;三枝恵真
                        </b-card-text>
                    </b-col>
                    <b-col md="4">
                        <b-card-img src="/himawari.jpg" alt="Image"
                                    class="rounded-0"></b-card-img>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </section>

</template>

<script lang="js">

    export default {
        name: 'Top',
        props: [],
        mounted() {

        },
        data() {
            return {}
        },
        methods: {},
        computed: {}
    }


</script>

<style scoped lang="scss">
    .Top {
        .jumbotron {
            background-image: url("/suzume.jpg");
            background-size: 50%;
            background-color: #FFFFFF;
            height: 20rem;

            .text-right {
                width: 50%;
                margin-left: 50%;
                color: #2c3e50;
                font-size: 1.5rem;
                font-weight: bold;
            }
        }

        .b-card {
            text-align: left;
        }
    }


</style>
