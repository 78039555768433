<template lang="html">

  <section class="contact">
    <b-card no-body class="overflow-hidden" style="width: 100%;">
      <b-card-body title="お問い合わせ">
        <b-row no-gutters>
          <b-col md="12">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc2Z6Ee5t5mHLuFHg6cjJSG1fE-0np-fDrClDr11fDVvfnUJA/viewform?embedded=true" width="100%" height="777" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>

</template>

<script lang="js">

  export default {
    name: 'Contact',
    props: [],
    mounted() {

    },
    data() {
      return {
        form: {
          email: '',
          name: '',
          text: '',
          tel: '',
        },
      }
    },
    methods: {},
    computed: {}
  }


</script>

<style scoped lang="scss">
  .contact {

  }
</style>
