<template lang="html">

  <section class="access">
    <b-card no-body class="overflow-hidden" style="width: 100%;">
          <b-card-body title="アクセス">
      <b-row no-gutters>
        <b-col md="6">

            <b-card-text>
              <div>〒105-0003</div>
              <div>東京都港区西新橋1-21-8 弁護士ビル302号室</div>
              <div>三枝法律事務所</div>

              <div>電話: 03-6807-5721</div>
              <div>FAX: 03-6807-5722</div>
              <div class="spacer"></div>
              <div>東京メトロ日比谷線 虎ノ門ヒルズ駅 徒歩4分</div>
              <div>東京メトロ銀座線 虎ノ門駅（1番出口）徒歩5分</div>
              <div>JR各線 新橋駅（烏森口）徒歩9分</div>
              <div>東京メトロ丸ノ内線 霞ヶ関駅 徒歩15分</div>
            </b-card-text>
        </b-col>
        <b-col md="6">
          <b-img src="/map.png" fluid alt="Fluid image"></b-img>
          <b-img src="/outside.jpg" fluid alt="Fluid image"></b-img>
        </b-col>
      </b-row>
          </b-card-body>
    </b-card>
  </section>

</template>

<script lang="js">

  export default  {
    name: 'Access',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .access {

  }
  .spacer {
    height: 1rem;
  }
</style>
